import React from 'react'
import Layout from '../components/Layout'
import { getNotFoundMessages } from '../messages/components-messages'

const messages = getNotFoundMessages()

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
                <header className="major">
                  <h1>{messages.title}</h1>
                </header>
                <p>{messages.description}</p>
                <a href="/" className="button special">{messages.initialPageButton}</a>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
